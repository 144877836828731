<template>
	<div id="reportIndex">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'ReportIndex',
};
</script>
